import { Role } from '@/helpers/role'

// export function camel (str) {
//   const camel = (str || '').replace(/-([^-])/g, g => g[1].toUpperCase());

//   return capitalize(camel);
// }

// export function camelActual (str) {
//   return (str || '').replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : ''));
// }

// export function kebab (str) {
//   return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
// }

// export function capitalize (str) {
//   str = str || '';

//   return `${str.substr(0, 1).toUpperCase()}${str.slice(1)}`;
// }

// export function findProduct (store, id) {
//   return store.state.store.products.find(p => p.id === id);
// }

// export function isOnSale (variants) {
//   return variants.some(variant => {
//     return parseFloat(variant.price) < parseFloat(variant.compareAtPrice);
//   });
// }

// export function randomNumber (min, max) {
//   return Math.floor(Math.random() * max) + min;
// }

// export function randomString (length = 5) {
//   let text = '';
//   const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

//   for (let i = 0; i < length; i++) {
//     text += possible.charAt(Math.floor(Math.random() * possible.length));
//   }

//   return text;
// }

export function randomPinNumber() {
  var pin = '';

  var random_number_1 = Math.floor(Math.random()* 9) + 1
  var random_number_2 = Math.floor(Math.random()* 9) + 1
  var random_number_3 = Math.floor(Math.random()* 9) + 1
  var random_number_4 = Math.floor(Math.random()* 9) + 1

  pin = random_number_1.toString() + "" + random_number_2.toString() + "" + random_number_3.toString() + "" + random_number_4.toString()

  return pin


}

const randomElement = (arr = []) => {
    return arr[Math.floor(Math.random() * arr.length)];
  };
  
  const kebab =  (str) => {
    return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  };
  
  const toggleFullScreen = () => {
    let doc = window.document;
    let docEl = doc.documentElement;
  
    let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    let cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
  
    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl);
    }
    else {
      cancelFullScreen.call(doc);
    }
  };

  export function getCurrentDate() {
      let dt = new Date()
      let currentDate = dt.getFullYear() + '-' + (parseInt(dt.getUTCMonth()) + 1) + '-' + dt.getDate()

      return currentDate
  }

  export function getCurrentTime() {
      let dt = new Date()
      return dt.getHours() + ":" + String(dt.getMinutes()).padStart(2, "0")
  }

  export function formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
  }

  export function getUserSettingsFromStorage(key) {
    var userSettings = JSON.parse(window.localStorage.getItem(key)) || null
    window.console.log(userSettings)
    return userSettings
  }

  export function storeUserSettingsToLocalStorage(key, data) {
    var userSettings = JSON.parse(window.localStorage.getItem(key)) || null

    if(userSettings == null) {
      userSettings = window.localStorage.setItem(key, JSON.stringify(data))
    }
    
    for(var property in data) {
      userSettings[property] = data[property]
    }

    window.localStorage.setItem(key, JSON.stringify(userSettings))
    window.console.log(userSettings)

    return userSettings
  }

  export function getUserRole() {
    var user = JSON.parse(window.localStorage.getItem('fl32aitqtu')) || null
    var userRole = null;
    
    if(user == null) {
      return null
    }

    if(user.type === Role.Admin) {
      userRole = Role.Admin
    } else if(user.type === Role.User) {
      userRole = Role.User
    } else if(user.type === Role.Observer) {
      userRole = Role.Observer
    } 
    else {
      userRole = null
    }

    //window.console.log(user)
    //window.console.log(user.type)

    return userRole

  }

  export function isAdmin() {
    var user = JSON.parse(window.localStorage.getItem('fl32aitqtu')) || null
    var userRole = null;
    
    if(user == null) {
      return null
    }

    if(user.type === Role.Admin) {
      return true;
    } else if(user.type === Role.User) {
      return false;
    } else {
      return null;
    }

  }


  export function create_UUID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });

    return uuid;
  }

  export function isMobileDevice(obj) {    
    if(obj.$vuetify.breakpoint.mdAndDown) {
      return true;
    }

    return false;
  }

  export function randomString(length) {
    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    var string_length = length;
    var randomstring = '';
    for (var i=0; i<string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum,rnum+1);
    }
    return randomstring
  }

  export function random5CharsTag() {
    return (+new Date).toString(36).slice(-5);
  }

  export function randomTagStringGenerator(len, an) {
    an = an && an.toLowerCase();
    var str = "",
      i = 0,
      min = an == "a" ? 10 : 0,
      max = an == "n" ? 10 : 62;
    for (; i++ < len;) {
      var r = Math.random() * (max - min) + min << 0;
      str += String.fromCharCode(r += r > 9 ? r < 36 ? 55 : 61 : 48);
    }
    return str;

    //console.log(randomTagStringGenerator(10));      // i.e: "4Z8iNQag9v"
    //console.log(randomTagStringGenerator(10, "a")); // i.e: "aUkZuHNcWw"
    //console.log(randomTagStringGenerator(10, "n")); // i.e: "9055739230"
  }
  
  export default {
    randomElement,
    toggleFullScreen,
    kebab
  };