import Vue from 'vue'
import VueRouter from 'vue-router'
import { Role } from '@/helpers/role'
import Login from '@/views/auth/Login.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/prijava',
        name: 'login',
        component: Login
    },
    {
        path: '/ponastavitev-gesla',
        name: 'passwordResetRequest',
        component: () => import('@/views/auth/PasswordResetRequest.vue')
    },
    {
        //path: '/ponastavitev-gesla/:token',
        path: '/ponastavitev-gesla/:token',
        name: 'passwordReset',
        component: () => import('@/views/auth/PasswordReset.vue')
    }, 
    /* ERRORS */
    {
        path: '*',
        name: '404',
        component: () => import('@/views/errors/404.vue')
    },
    {
        path: '/403',
        name: '403',
        component: () => import('@/views/errors/403.vue')
    },
    {
        path: '/500',
        name: '500',
        component: () => import('@/views/errors/500.vue')
    },

    {
        path: '/',
        component: () => import('@/views/RootView.vue'),
        children: [
            /** ADMIN PAGES */
            {
                path: '/admin/nadzorna-plosca',
                name: 'admin_dashboard' ,
                component: () => import('@/views/admin/Dashboard.vue'),
                meta: { requiresAuth: true, authorize: [Role.Admin, Role.Observer]}
            },
            {
                path: '/admin/nastavitve',
                name: 'admin_settings',
                component: () => import('@/views/admin/Settings.vue'),
                meta: { requiresAuth: true, authorize: [Role.Admin]}
            },
            {
                path: '/admin/seznam-uporabnikov',
                name: 'admin_users_list',
                component: () => import('@/views/admin/Users.vue'),
                meta: { requiresAuth: true, authorize: [Role.Admin]}
            },
            {
                path: '/admin/dodaj-uporabnika',
                name: 'admin_add_user',
                component: () => import('@/views/admin/AddUser.vue'),
                meta: { requiresAuth: true, authorize: [Role.Admin]}
            },
            {
                path: '/admin/seznam-uporabnikov/:id',
                name: 'admin_edit_user',
                component: () => import('@/views/admin/EditUser.vue'),
                meta: { requiresAuth: true, authorize: [Role.Admin]}
            },
            {
                path:'/admin/zapornice',
                name: 'parking_barriers',
                component: () => import('@/views/admin/ParkingBarriers.vue'),
                meta: { requiresAuth: true, authorize: [Role.Admin] }
            },

            /** USER PAGES */
            {
                path: '/nadzorna-plosca',
                name: 'dashboard' ,
                component: () => import('@/views/user/Dashboard.vue'),
                meta: { requiresAuth: true, authorize: [Role.User] }
            },
            {
                path: '/nastavitve',
                name: 'settings',
                component: () => import('@/views/user/Settings.vue'),
                meta: { requiresAuth: true, authorize: [Role.User]}
            },
        ]
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: function(to, from, savedPosition) {
        return savedPosition || { x: 0, y: 0 }
    },
    linkActiveClass: 'v-list__tile--active router-link-active active',
    routes
})

export default router