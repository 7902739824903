export default {
    app: {
        drawerTitle: 'Nadzorni Center',
        title: 'Nadzorni Center - Gubčeva',
        shortTitle: 'NC - Gubčeva',
        icon: 'language',
        appKey: 'fl32aitqtu'
    },

    user: {
        id: null,
        name: null,
        email: null,
        type: null,
        permissions: null,
        access_token: null,
        last_seen: null
    },

    drawer: null,
    loader: false,
    progress: false,
    loaderText: '',
    toolbarTitle: '',
    search: '',
    searchFieldState: false,
    mobileSearch: false,

    snackbar: {
        display: false,
        y: 'bottom',
        x: 'left',
        mode: '',
        timeout: 7000,
        text: 'Hello, I\'m a snackbar'
    },

    access_log: [],
    currentRoute: null
}