const AdminMenu  = [
    { header: 'Nadzorna Plošča' },
    {
        name: 'admin_dashboard',
        title: 'Pregled',
        icon: 'mdi-view-dashboard',
        path: '/nadzorna-plosca',
        group: 'dashboard'
    },
    /*{
        name: 'settings',
        title: 'Nastavitve',
        icon: 'mdi-cogs',
        path: 'admin/nastavitve',
        group: 'settings'
    },
    */
    {
        name: 'admin_users_list',
        title: 'Seznam uporabnikov',
        icon: 'mdi-account-group',
        path: 'admin/seznam-uporabnikov',
        group: 'users_list'
    },
    {
        name: 'admin_add_user',
        title: 'Dodaj uporabnika',
        icon: 'mdi-account-plus',
        path: '/admin/dodaj-uporabnika',
        group: 'add_user',
    },
    {
        name: 'parking_barriers',
        title: 'Upravljanje zapornic',
        icon: 'mdi-remote',
        path: '/admin/zapornice',
        group: 'parking_barriers',
    },
];
const UserMenu = [
    { header: 'Nadzorna Plošča' },
    {
        name: 'dashboard',
        title: 'Pregled',
        icon: 'mdi-view-dashboard',
        path: '/pregled',
        group: 'dashboard'
    },

    {
        name: 'settings',
        title: 'Nastavitve',
        icon: 'mdi-cogs',
        path: '/nastavitve',
        group: 'settings'
    }
];

const ObserverMenu = [
    { header: 'Nadzorna Plošča' },
    {
        name: 'admin_dashboard',
        title: 'Pregled',
        icon: 'mdi-view-dashboard',
        path: '/nadzorna-plosca',
        group: 'dashboard'
    }
]

export { AdminMenu, UserMenu, ObserverMenu }