<template>
    <v-app id="inspire">
        <v-main>
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                <v-flex xs12 sm8 md8 lg4>
                    <v-card class="elevation-5 pa-3">
                    <v-card-text>
                        <div class="layout column align-center"><br>
                        <img :src="getLogo" alt="Nadzorni center - Prijava" width="140px;"  height="auto">
                        <br><br>
                        <div>
                            <p class="headline font-weight-medium text-center">Nadzorni center - Prijava</p>
                        </div>
                        <div>
                            <p class="title font-weight-medium text-center">Prijava</p>
                        </div>
                        <v-layout align-center justify-center="">
                            <v-flex xs12 sm12 md12 lg12>
                                <div class="">
                                    <p style="text-align:center;" class="font-weight-medium">Vnesite elektronski naslov in geslo za vstop v sistem.</p>
                                </div>
                            </v-flex>
                        </v-layout>
                        </div>                
                        <br>
                        <v-form ref="loginForm" v-model="valid" lazy-validation>
                        <v-text-field
                            append-icon="mdi-email"
                            name="login" label="E-mail" 
                            type="email" 
                            :rules="emailRules" 
                            v-model="credentials.username"
                            :error="error"
                            :error-messages="errors['message']"
                            @keyup.enter="login"
                            color="tertiary"
                            >
                        </v-text-field>
                        <v-text-field id="password" append-icon="mdi-lock" name="password" label="Geslo" type="password" :rules="passwordRules" v-model="credentials.password" @keyup.enter="login" color="tertiary"></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions v-if="!$vuetify.breakpoint.xsOnly">
                        <v-spacer></v-spacer>
                        <v-btn class="white--text" dark color="secondary" @click.native="login" :loading="loginLoading">Prijava</v-btn>
                        <v-btn class="white--text" dark color="secondary" @click.native='passwordReset'>Pozabljeno geslo?</v-btn>
                        <br><br>
                    </v-card-actions>
                    <v-card-text v-else>
                        <v-btn block outlined  style="margin-bottom:10px;" class="white--text" dark color="secondary" @click.native="login" :loading="loginLoading">Prijava</v-btn>
                        <v-btn outlined block class="white--text" dark color="secondary" @click.native='passwordReset'>Pozabljeno geslo?</v-btn>
                    </v-card-text>
                    </v-card>
                </v-flex>
                </v-layout>
            </v-container>
            <!--<network-status></network-status>-->
        </v-main>
    </v-app> 
</template>

<script>
import router from '@/router'
import { post } from '@/helpers/api'
//import logo from '@/assets/logo.gif'
import logo from '@/assets/nc_logo.png'
import  { Role } from '@/helpers/role'

export default {
    name: 'login',
    props: {
        source: String
    },

    data: () => ({
        title: 'NC - Gubčeva',
        valid: true,
        avatarSize: 32,
        tile: true,
        drawer: null,
        credentials: {
            username: '',
            password: ''
        },

        emailRules: [
            (v) => !!v || 'Elektronski naslov je obvezen.',
            (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Vneseni elektronski naslov ni veljaven.'
        ],
        passwordRules: [
            (v) => !!v || 'Geslo je obvezno.',
            (v) => v.length >= 6 || 'Geslo mora vsebovati vsaj 8 mestni zapis.'
        ],

        loginLoading: false,
        error: false,
        errors: {}
    }),

    computed: {
        getLogo() {
            return logo
        }
    },

    methods: {
        login() {
           if(this.$refs.loginForm.validate()) {
                this.$recaptcha("login").then(token => {
                    this.loginLoading = true;
                    this.error = false;
                    this.errors = {}
                    this.credentials.grant_type = process.env.VUE_APP_GRANT_TYPE;
                    this.credentials.client_id = process.env.VUE_APP_CLIENT_ID;

                    this.credentials["g-recaptcha-response"] = token;
                    this.$store.dispatch('LOGIN', this.credentials)
                    .then(response => {
                        //window.console.log(response)
                        //if(response.data.hasOwnProperty('type')) {
                            if(Object.prototype.hasOwnProperty.call(response.data, 'type') ) {
                            if((Role.Admin === response.data.type) || (Role.Observer === response.data.type)) {
                                router.push({ name: 'admin_dashboard'})
                            }
                            else {
                                router.push({ name: 'dashboard'});
                            }

                        } else {
                            //backend logout
                            //clear local storage user data
                            window.console.log("Login response data has no 'type' property.");
                        }

                    })
                    .catch(error => {
                        window.console.log("Error")
                        window.console.log(error.response)
                        window.console.log(error)
                        this.error = true;
                        this.loginLoading = false;
                        if(error.response.status == 401) {
                        this.errors = { message: "Napačno uporabniško ime ali geslo." }
                        }
                        else {
                        this.errors = { message: "Pri prijavi v sistem je prišlo do napake."}
                        }
                    })
                    .then(() => {
                        this.loginLoading = false;
                    })
                });
            } 
        },

        passwordReset() {
            this.$router.push({
                name: 'passwordResetRequest'
            })
        }
    },

    created() {

    }
}

</script>

<style scoped>
    #inspire{
        background-image: url("../../assets/bckpattern.png") !important;
        background-repeat: repeat !important;
    }
</style>