import { post, get, destroy, API_ENDPOINT } from '@/helpers/api'

export default {
    ROUTE_NAME (context, routeName) {
        context.commit('SET_CURRENT_ROUTE', routeName)
    },

    LOGIN (context, credentials) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'auth/login', credentials) 
                .then(response => {
                    context.commit('SET_USER', response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
  
    LOGOUT (context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'auth/logout')
            .then(response => {
                context.commit('SET_USER', null)
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
  
    PASSWORD_RESET_REQUEST (context, email) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'password/create', email)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PASSWORD_RESET (context, credentials) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'password/reset', credentials)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PASSWORD_RESET_TOKEN_FIND(context, token) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'password/find/' + token)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

      CONVERT_RFID_NR (context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'v1/admin/rfid-nr', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    RFID_NR_SEARCH (context, nr) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'v1/admin/rfid-nr/' + nr)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    
    CREATE_RFID_USER (context, data) {
        return new Promise ((resolve, reject) => {
            post(API_ENDPOINT + 'v1/admin/users/create', data )
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    GET_RFID_USER (context, userId) {
        return new Promise((resolve, reject) => {
            get(API_ENDPOINT + 'v1/admin/users/' + userId)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    UPDATE_RFID_USER(context, data) {
        return new Promise((resolve, reject) => {
            post(API_ENDPOINT + 'v1/admin/users/' + data.id + '/update', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    DELETE_RFID_USER (context, id) {
        return new Promise((resolve, reject) => {
            destroy(API_ENDPOINT + 'v1/admin/users/' + id + '/delete')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    ADMIN_RESOURCES(context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/admin/resources')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    USER_RESOURCES(context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/user/resources')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    USER_DATA(context) {
        return new Promise( (resolve, reject) => {
            get(API_ENDPOINT + 'v1/user/data')
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    UPDATE_USER_DATA(context, data) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'v1/user/data/update', data)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    PUBLISH (context, payload) {
        return new Promise( (resolve, reject) => {
            post(API_ENDPOINT + 'v1/admin/mqtt-publish', payload)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
}