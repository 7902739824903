export default {
    SET_USER(state, data) {
        if(data == null)
        {
            state.user.id = null
            state.user.name = null
            state.user.email = null
            state.user.type = null
            state.user.reset = null
            state.user.last_seen = null
            state.user.access_token = ""
        }
        else
        {
            state.user.id = data.id
            state.user.name = data.name
            state.user.email = data.email
            state.user.type = data.type
            state.user.reset = data.reset
            state.user.last_seen = data.last_seen
            state.user.access_token = data.access_token
        }

        localStorage.setItem('fl32aitqtu', JSON.stringify(state.user))
    },

    SET_DRAWER (state, value) {
        state.drawer = value
    },

    SET_TOOLBAR_TITLE (state, title) {
        state.toolbarTitle = title
    },

    SET_LOADER (state, value) {
        state.loader = value
    },

    SET_LOADER_TEXT (state, text) {
        state.loaderText = text
    },

    SET_SEARCH (state, search) {
        state.search = search
    },

    SET_SEARCH_FIELD_STATE (state, fieldState) {
        state.searchFieldState = fieldState
    },

    SET_MOBILE_SEARCH (state, mobileSearchState) {
        state.mobileSearch = mobileSearchState
    },

    SET_PROGRESS (state, value) {
        state.progress = value
    },

    SET_CURRENT_ROUTE (state, routeName) {
        state.currentRoute = routeName
    },

    SET_SNACKBAR_TEXT (state, text) {
        state.snackbar.text = text
    },

    SET_SNACKBAR_DISPLAY (state, display) {
        state.snackbar.display = display
    },
    
    SET_SNACKBAR_Y (state, y) {
        state.snackbar.y = y
    },

    SET_SNACKBAR_X (state, x) {
        state.snackbar.x = x
    },

    SET_SNACKBAR_MODE (state, mode) {
        state.snackbar.mode = mode
    },

    SET_SNACKBAR_TIMEOUT (state, timeout) {
        state.snackbar.timeout = timeout
    },

    setSearch (state, search) {
        state.search = search
    },

    snackbarDisplay (state, display) {
        state.snackbar.display = display
    },

    snackbarY (state, y) {
        state.snackbar.y = y
    },

    snackbarX (state, x) {
        state.snackbar.x = x
    },

    snackbarMode (state, mode) {
        state.snackbar.mode = mode
    },

    snackbarTimeout (state, timeout) {
        state.snackbar.timeout = timeout
    },

    snackbarText (state, text) {
        state.snackbar.text = text
    }
}